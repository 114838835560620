:root {
  --_134tqwz0: 36px;
  --_134tqwz1: 32px;
  --_134tqwz2: 24px;
  --_134tqwz3: 20px;
  --_134tqwz4: 18px;
  --_134tqwz5: 16px;
  --_134tqwz6: 14px;
  --_134tqwz7: 20px;
  --_134tqwz8: 17px;
  --_134tqwz9: 15px;
  --_134tqwza: 13px;
  --_134tqwzb: 12px;
  --_134tqwzc: 10px;
  --_134tqwzd: 14px;
  --_134tqwze: 16px;
  --_134tqwzf: 44px;
  --_134tqwzg: 36px;
  --_134tqwzh: 30px;
  --_134tqwzi: 24px;
  --_134tqwzj: 22px;
  --_134tqwzk: 20px;
  --_134tqwzl: 18px;
  --_134tqwzm: 30px;
  --_134tqwzn: 24px;
  --_134tqwzo: 22px;
  --_134tqwzp: 20px;
  --_134tqwzq: 18px;
  --_134tqwzr: 16px;
  --_134tqwzs: 20px;
  --_134tqwzt: 4px;
  --_134tqwzu: 8px;
  --_134tqwzv: 12px;
  --_134tqwzw: 16px;
  --_134tqwzx: 32px;
  --_134tqwzy: 2px;
  --_134tqwzz: 50%;
  --_134tqwz10: 20px;
  --_134tqwz11: 0px;
  --_134tqwz12: 1px;
  --_134tqwz13: 2px;
  --_134tqwz14: 3px;
  --_134tqwz15: 1.5px;
  --_134tqwz16: 1.2px;
  --_134tqwz17: 1px;
  --_134tqwz18: 0.5px;
  --_134tqwz19: 0.2px;
  --_134tqwz1a: rgba(243,243,244,0.04);
  --_134tqwz1b: rgba(243,243,244,0.16);
  --_134tqwz1c: #000000;
  --_134tqwz1d: #FFFFFF;
  --_134tqwz1e: #121221;
  --_134tqwz1f: #2DB2E0;
  --_134tqwz1g: #5AD4FF;
  --_134tqwz1h: #4FB1F9;
  --_134tqwz1i: #5B9EFF;
  --_134tqwz1j: #63C5FF;
  --_134tqwz1k: #F8F8FA;
  --_134tqwz1l: #E8E8EA;
  --_134tqwz1m: #D1D1D5;
  --_134tqwz1n: #B3B2B8;
  --_134tqwz1o: #95949B;
  --_134tqwz1p: #77767E;
  --_134tqwz1q: #595861;
  --_134tqwz1r: #3C3B44;
  --_134tqwz1s: #1E1D26;
  --_134tqwz1t: #2A292E;
  --_134tqwz1u: #A35200;
  --_134tqwz1v: #206444;
  --_134tqwz1w: #EBFFFC;
  --_134tqwz1x: #C9FFF1;
  --_134tqwz1y: #ADFFE2;
  --_134tqwz1z: #94FFCF;
  --_134tqwz20: #81FFBB;
  --_134tqwz21: #72F2A6;
  --_134tqwz22: #62D89A;
  --_134tqwz23: #52BD8D;
  --_134tqwz24: #736285;
  --_134tqwz25: #605172;
  --_134tqwz26: #544868;
  --_134tqwz27: #4A405E;
  --_134tqwz28: #3F3754;
  --_134tqwz29: #353547;
  --_134tqwz2a: #352F4A;
  --_134tqwz2b: #302F4A;
  --_134tqwz2c: #2D2D40;
  --_134tqwz2d: #2B2740;
  --_134tqwz2e: #211F35;
  --_134tqwz2f: #1C1B2E;
  --_134tqwz2g: #171629;
  --_134tqwz2h: #21212E;
  --_134tqwz2i: #FDF5FF;
  --_134tqwz2j: #F3D8FF;
  --_134tqwz2k: #E5BDFC;
  --_134tqwz2l: #D3A5F7;
  --_134tqwz2m: #C08FEE;
  --_134tqwz2n: #AB7DE3;
  --_134tqwz2o: #966DD5;
  --_134tqwz2p: #7A5AB9;
  --_134tqwz2q: #60479C;
  --_134tqwz2r: #FFEDEB;
  --_134tqwz2s: #FFA8AE;
  --_134tqwz2t: #FF8395;
  --_134tqwz2u: #F74B75;
  --_134tqwz2v: #E7366C;
  --_134tqwz2w: #CB2863;
  --_134tqwz2x: #AC1D58;
  --_134tqwz2y: #8C134C;
  --_134tqwz2z: #780038;
  --_134tqwz30: #A61807;
  --_134tqwz31: #FFFFD5;
  --_134tqwz32: #FFFF92;
  --_134tqwz33: #FFF076;
  --_134tqwz34: #F9E280;
  --_134tqwz35: #F7DB60;
  --_134tqwz36: #E8C34D;
  --_134tqwz37: #CBA13D;
  --_134tqwz38: #94833A;
  --_134tqwz39: #AC802E;
  --_134tqwz3a: #8B6121;
  --_134tqwz3b: #353548;
  --_134tqwz3c: #343445;
  --_134tqwz3d: #282939;
  --_134tqwz3e: #1b1c29;
  --_134tqwz3f: #50FAAB;
  --_134tqwz3g: #FF63EF;
  --_134tqwz3h: #FC7413;
  --_134tqwz3i: rgba(0,0,0,0.025);
  --_134tqwz3j: rgba(0,0,0,0.05);
  --_134tqwz3k: rgba(0,0,0,0.1);
  --_134tqwz3l: rgba(0,0,0,0.2);
  --_134tqwz3m: rgba(0,0,0,0.3);
  --_134tqwz3n: rgba(0,0,0,0.4);
  --_134tqwz3o: rgba(0,0,0,0.5);
  --_134tqwz3p: rgba(0,0,0,0.6);
  --_134tqwz3q: rgba(0,0,0,0.7);
  --_134tqwz3r: rgba(0,0,0,0.8);
  --_134tqwz3s: rgba(0,0,0,0.9);
  --_134tqwz3t: rgba(255,255,255,0.025);
  --_134tqwz3u: rgba(255,255,255,0.05);
  --_134tqwz3v: rgba(255,255,255,0.1);
  --_134tqwz3w: rgba(255,255,255,0.2);
  --_134tqwz3x: rgba(255,255,255,0.3);
  --_134tqwz3y: rgba(255,255,255,0.4);
  --_134tqwz3z: rgba(255,255,255,0.5);
  --_134tqwz40: rgba(255,255,255,0.6);
  --_134tqwz41: rgba(255,255,255,0.7);
  --_134tqwz42: rgba(255,255,255,0.8);
  --_134tqwz43: rgba(255,255,255,0.9);
  --_134tqwz44: #FFE0FC;
  --_134tqwz45: #FFC1F9;
  --_134tqwz46: #FFA1F5;
  --_134tqwz47: #FF82F2;
  --_134tqwz48: #FF63EF;
  --_134tqwz49: #CC4FBF;
  --_134tqwz4a: #993B8F;
  --_134tqwz4b: #662860;
  --_134tqwz4c: #331430;
  --_134tqwz4d: #FAD7E2;
  --_134tqwz4e: #F5AFC4;
  --_134tqwz4f: #F186A7;
  --_134tqwz4g: #EC5E89;
  --_134tqwz4h: #E7366C;
  --_134tqwz4i: #B92B56;
  --_134tqwz4j: #8B2041;
  --_134tqwz4k: #5C162B;
  --_134tqwz4l: #2E0B16;
  --_134tqwz4m: #E0F3FF;
  --_134tqwz4n: #C1E8FF;
  --_134tqwz4o: #A1DCFF;
  --_134tqwz4p: #82D1FF;
  --_134tqwz4q: #63C5FF;
  --_134tqwz4r: #4F9ECC;
  --_134tqwz4s: #3B7699;
  --_134tqwz4t: #284F66;
  --_134tqwz4u: #142733;
  --_134tqwz4v: #F2E9FC;
  --_134tqwz4w: #E6D2F8;
  --_134tqwz4x: #D9BCF5;
  --_134tqwz4y: #CDA5F1;
  --_134tqwz4z: #C08FEE;
  --_134tqwz50: #9A72BE;
  --_134tqwz51: #73568F;
  --_134tqwz52: #4D395F;
  --_134tqwz53: #261D30;
  --_134tqwz54: #FEE3D0;
  --_134tqwz55: #FEC7A1;
  --_134tqwz56: #FDAC71;
  --_134tqwz57: #FD9042;
  --_134tqwz58: #FC7413;
  --_134tqwz59: #CA5D0F;
  --_134tqwz5a: #97460B;
  --_134tqwz5b: #652E08;
  --_134tqwz5c: #321704;
  --_134tqwz5d: #FDF8DF;
  --_134tqwz5e: #FCF1BF;
  --_134tqwz5f: #FAE9A0;
  --_134tqwz5g: #F9E280;
  --_134tqwz5h: #F7DB60;
  --_134tqwz5i: #C6AF4D;
  --_134tqwz5j: #94833A;
  --_134tqwz5k: #635826;
  --_134tqwz5l: #312C13;
  --_134tqwz5m: #DCFEEE;
  --_134tqwz5n: #B9FDDD;
  --_134tqwz5o: #96FCCD;
  --_134tqwz5p: #73FBBC;
  --_134tqwz5q: #50F9AB;
  --_134tqwz5r: #40C889;
  --_134tqwz5s: #309667;
  --_134tqwz5t: #206444;
  --_134tqwz5u: #103222;
  --_134tqwz5v: #F9F9FA;
  --_134tqwz5w: #F3F3F4;
  --_134tqwz5x: #E8E8EA;
  --_134tqwz5y: #D1D0D4;
  --_134tqwz5z: #B9B9BF;
  --_134tqwz60: #A2A2A9;
  --_134tqwz61: #8B8A94;
  --_134tqwz62: #74737F;
  --_134tqwz63: #5D5C69;
  --_134tqwz64: #454554;
  --_134tqwz65: #171629;
  --_134tqwz66: #103222;
  --_134tqwz67: #40C889;
  --_134tqwz68: #50F9AB;
  --_134tqwz69: #73FBBC;
  --_134tqwz6a: #312C13;
  --_134tqwz6b: #94833A;
  --_134tqwz6c: #F7DB60;
  --_134tqwz6d: #F9E280;
  --_134tqwz6e: #2E0B16;
  --_134tqwz6f: #8B2041;
  --_134tqwz6g: #E7366C;
  --_134tqwz6h: #EC5E89;
  --_134tqwz6i: #321704;
  --_134tqwz6j: #97460B;
  --_134tqwz6k: #FC7413;
  --_134tqwz6l: #FDAC71;
  --_134tqwz6m: #142733;
  --_134tqwz6n: #63C5FF;
  --_134tqwz6o: rgba(0,0,0,0.1);
  --_134tqwz6p: rgba(0,0,0,0.2);
  --_134tqwz6q: rgba(0,0,0,0.4);
  --_134tqwz6r: rgba(255,255,255,0.025);
  --_134tqwz6s: rgba(255,255,255,0.05);
  --_134tqwz6t: rgba(255,255,255,0.1);
  --_134tqwz6u: rgba(255,255,255,0.2);
  --_134tqwz6v: #171629;
  --_134tqwz6w: #454554;
  --_134tqwz6x: #5D5C69;
  --_134tqwz6y: #74737F;
  --_134tqwz6z: #A2A2A9;
  --_134tqwz70: #C08FEE;
  --_134tqwz71: #E7366C;
  --_134tqwz72: #50F9AB;
  --_134tqwz73: #F7DB60;
  --_134tqwz74: rgba(255,255,255,0.025);
  --_134tqwz75: rgba(255,255,255,0.05);
  --_134tqwz76: rgba(255,255,255,0.1);
  --_134tqwz77: rgba(255,255,255,0.2);
  --_134tqwz78: rgba(255,255,255,0.5);
  --_134tqwz79: rgba(255,255,255,0.7);
  --_134tqwz7a: #FFFFFF;
  --_134tqwz7b: #63C5FF;
  --_134tqwz7c: #F7DB60;
  --_134tqwz7d: #82D1FF;
  --_134tqwz7e: #50F9AB;
  --_134tqwz7f: #EC5E89;
  --_134tqwz7g: #FC7413;
  --_134tqwz7h: #C08FEE;
  --_134tqwz7i: rgba(255,255,255,0.3);
  --_134tqwz7j: rgba(255,255,255,0.5);
  --_134tqwz7k: rgba(255,255,255,0.7);
  --_134tqwz7l: #FFFFFF;
  --_134tqwz7m: rgba(0,0,0,0.2);
  --_134tqwz7n: rgba(0,0,0,0.6);
  --_134tqwz7o: #000000;
  --_134tqwz7p: 0px;
  --_134tqwz7q: 8px;
  --_134tqwz7r: 16px;
  --_134tqwz7s: 24px;
  --_134tqwz7t: 32px;
  --_134tqwz7u: 40px;
  --_134tqwz7v: 48px;
  --_134tqwz7w: 56px;
  --_134tqwz7x: 64px;
  --_134tqwz7y: 72px;
  --_134tqwz7z: 80px;
  --_134tqwz80: 88px;
  --_134tqwz81: 96px;
  --_134tqwz82: 104px;
  --_134tqwz83: 112px;
  --_134tqwz84: 120px;
  --_134tqwz85: 128px;
  --_134tqwz86: 136px;
  --_134tqwz87: 144px;
  --_134tqwz88: 152px;
  --_134tqwz89: 160px;
  --_134tqwz8a: 168px;
  --_134tqwz8b: 176px;
  --_134tqwz8c: 184px;
  --_134tqwz8d: 192px;
  --_134tqwz8e: 200px;
  --_134tqwz8f: 208px;
  --_134tqwz8g: 216px;
  --_134tqwz8h: 224px;
  --_134tqwz8i: 232px;
  --_134tqwz8j: 240px;
  --_134tqwz8k: 248px;
  --_134tqwz8l: 256px;
  --_134tqwz8m: 264px;
  --_134tqwz8n: 272px;
  --_134tqwz8o: 4px;
  --_134tqwz8p: 12px;
  --_134tqwz8q: 20px;
  --_134tqwz8r: 28px;
  --_134tqwz8s: 36px;
  --_134tqwz8t: 44px;
  --_134tqwz8u: 52px;
  --_134tqwz8v: 60px;
  --_134tqwz8w: 68px;
  --_134tqwz8x: 76px;
  --_134tqwz8y: 84px;
  --_134tqwz8z: 92px;
  --_134tqwz90: 100px;
  --_134tqwz91: 108px;
  --_134tqwz92: 116px;
  --_134tqwz93: 124px;
  --_134tqwz94: 132px;
  --_134tqwz95: 140px;
  --_134tqwz96: 148px;
  --_134tqwz97: 156px;
  --_134tqwz98: 164px;
  --_134tqwz99: 172px;
  --_134tqwz9a: 180px;
  --_134tqwz9b: 188px;
  --_134tqwz9c: 196px;
  --_134tqwz9d: 204px;
  --_134tqwz9e: 212px;
  --_134tqwz9f: 220px;
  --_134tqwz9g: 228px;
  --_134tqwz9h: 236px;
  --_134tqwz9i: 244px;
  --_134tqwz9j: 252px;
  --_134tqwz9k: 260px;
  --_134tqwz9l: 268px;
  --_134tqwz9m: 276px;
  --_134tqwz9n: 0px;
  --_134tqwz9o: 8px;
  --_134tqwz9p: 16px;
  --_134tqwz9q: 24px;
  --_134tqwz9r: 32px;
  --_134tqwz9s: 40px;
  --_134tqwz9t: 48px;
  --_134tqwz9u: 56px;
  --_134tqwz9v: 64px;
  --_134tqwz9w: 72px;
  --_134tqwz9x: 80px;
  --_134tqwz9y: 88px;
  --_134tqwz9z: 96px;
  --_134tqwza0: 104px;
  --_134tqwza1: 112px;
  --_134tqwza2: 120px;
  --_134tqwza3: 128px;
  --_134tqwza4: 136px;
  --_134tqwza5: 144px;
  --_134tqwza6: 152px;
  --_134tqwza7: 160px;
  --_134tqwza8: 168px;
  --_134tqwza9: 176px;
  --_134tqwzaa: 184px;
  --_134tqwzab: 192px;
  --_134tqwzac: 200px;
  --_134tqwzad: 208px;
  --_134tqwzae: 216px;
  --_134tqwzaf: 224px;
  --_134tqwzag: 232px;
  --_134tqwzah: 240px;
  --_134tqwzai: 248px;
  --_134tqwzaj: 256px;
  --_134tqwzak: 264px;
  --_134tqwzal: 272px;
  --_134tqwzam: 4px;
  --_134tqwzan: 12px;
  --_134tqwzao: 20px;
  --_134tqwzap: 28px;
  --_134tqwzaq: 36px;
  --_134tqwzar: 44px;
  --_134tqwzas: 52px;
  --_134tqwzat: 60px;
  --_134tqwzau: 68px;
  --_134tqwzav: 76px;
  --_134tqwzaw: 84px;
  --_134tqwzax: 92px;
  --_134tqwzay: 100px;
  --_134tqwzaz: 108px;
  --_134tqwzb0: 116px;
  --_134tqwzb1: 124px;
  --_134tqwzb2: 132px;
  --_134tqwzb3: 140px;
  --_134tqwzb4: 148px;
  --_134tqwzb5: 156px;
  --_134tqwzb6: 164px;
  --_134tqwzb7: 172px;
  --_134tqwzb8: 180px;
  --_134tqwzb9: 188px;
  --_134tqwzba: 196px;
  --_134tqwzbb: 204px;
  --_134tqwzbc: 212px;
  --_134tqwzbd: 220px;
  --_134tqwzbe: 228px;
  --_134tqwzbf: 236px;
  --_134tqwzbg: 244px;
  --_134tqwzbh: 252px;
  --_134tqwzbi: 260px;
  --_134tqwzbj: 268px;
  --_134tqwzbk: 276px;
}
._17lrcdw0 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._17lrcdw1 {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: var(--_134tqwzt);
  border-bottom-left-radius: var(--_134tqwzt);
  border-color: var(--_134tqwz64);
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--_134tqwz8o);
}
._17lrcdw2 {
  width: 16px;
  height: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._17lrcdw3 {
  opacity: 0;
}
._17lrcdw4 {
  border-top-right-radius: var(--_134tqwzt);
  border-bottom-right-radius: var(--_134tqwzt);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: var(--_134tqwz64);
  border-left: none;
  line-height: var(--_134tqwzr);
  height: 42px;
}
._17lrcdw5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--_134tqwz7q);
  padding: var(--_134tqwz7q);
  border-bottom: 1px solid var(--_134tqwz64);
  border-radius: var(--_134tqwzt);
  background-color: transparent;
  color: var(--_134tqwz5y);
  border: none;
  cursor: pointer;
  margin: 0 var(--_134tqwz8o);
}
._17lrcdw5:hover {
  background-color: var(--_134tqwz2c);
}
._17lrcdw6 {
  border: none;
  background-color: transparent;
  color: var(--_134tqwz5y);
  width: 24px;
  height: -moz-fit-content;
  height: fit-content;
  margin-right: var(--_134tqwz8o);
}
._17lrcdw6 svg {
  width: 24px;
  border-radius: var(--_134tqwzt);
  margin-bottom: -2px;
}
._17lrcdw7 {
  width: 24px;
  height: 16px;
  border-radius: var(--_134tqwzt);
  background-color: var(--_134tqwz64);
}
._17lrcdw8 {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
  padding: var(--_134tqwz8o) 0;
}
._17lrcdw9 {
  color: var(--_134tqwz5y);
  margin-left: auto;
}
._17lrcdwa {
  width: 16px;
  height: 16px;
  opacity: 0;
}
._17lrcdwb {
  opacity: 1;
}
._1x9t3ya0 {
  display: flex;
  flex-direction: column;
  gap: var(--_134tqwz7q);
}
._1x9t3ya1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._1x9t3ya2 {
  border-top-right-radius: var(--_134tqwzt);
  border-bottom-right-radius: var(--_134tqwzt);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: var(--_134tqwz64);
  border-left: none;
  line-height: var(--_134tqwzr);
  height: 42px;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_179fbf';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_179fbf {font-family: '__Inter_179fbf', '__Inter_Fallback_179fbf', Roboto, sans-serif;font-style: normal
}.__variable_179fbf {--fonts-inter: '__Inter_179fbf', '__Inter_Fallback_179fbf', Roboto, sans-serif
}

